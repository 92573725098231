window.navigationHandler = () => {
  const HIDE_CLASS = 'navigation__hide';
  const ACTIVE_NAV_BAR_BTN_CLASS = 'navigation__barListBtn--active';
  const mobileNavClass = 'navigation--mobile';
  const ACTIVE_CONTENT_BUTTON = 'navigationButton__content--active';

  // Common DOM Elements
  const navContainer = document.querySelector('.jsNavigation');
  const navSectionsContainer = document.querySelector('.jsNavigationContentContainer');
  const navContentContainer = document.querySelector('.jsNavigationContentContainer');
  const navBarButtons = document.querySelectorAll('.jsNavigationBarButtons .navigation__barListBtn');
  const menuButton = document.querySelector('.jsNavigationMobileMenuButton');
  const inputContainer = document.querySelector(".jsNavigationSearchInputContainer");
  const searchButton = document.querySelector(".jsNavigationSearchButton");
  const regionButton = document.querySelector('.jsOpenRegionModalBtn');
  const contactFormLink = document.querySelector('.jsNavContactFormLink');
  const contentLists = document.querySelectorAll('.jsNavContentList a[data-nav-content-section-id]');
  const contentListsButtons = document.querySelectorAll('.jsNavContentList button[data-nav-content-section-id]');
  const allProductsLists = document.querySelectorAll('.jsNavProductsList')
  const topBarButtons = document.querySelector('.jsNavTopBarButtons');
  const mobileSearch = document.querySelector('.jsNavMobileSearch');
  const navButtonsList = document.querySelector('.jsNavigationBarButtons');
  const navSections = document.querySelectorAll('.jsNavigationContentContainer .navigationContent__container');
  const navBackButtons = document.querySelectorAll('.jsNavBackButton');
  const navBackButtons2 = document.querySelectorAll('.jsNavBackButton2Level');
  const jsNavHoverListItems = document.querySelectorAll('.jsNavHoverListItem');
  const allSecondLvlLists = document.querySelectorAll(".navList__list--listSecondLevel");
  const productButtons = document.querySelectorAll('.jsNavProductButton');
  const productActionList = document.querySelector('.jsProductActionList');
  const regionModal = document.querySelector('.jsNavRegionModal');
  const closeRegionModalBtn = document.querySelector('.jsNavCloseRegionModal');
  const insightLiveBtn = document.querySelector('.jsNavMobileInsightLiveBtn');
  const insightLiveList = document.querySelector('.jsNavInsightsLiveList');
  const subjectExportSeriesBtn = document.querySelector('.jsNavMobileSESBtn');
  const subjectExportSeriesList = document.querySelector('.jsNavSESList');
  const navContentContainers = document.querySelectorAll('.jsNavigationContentContainer');
  const userBtn = document.querySelector('.jsNavUserBtn');
  const userMenuCloseBtn = document.querySelector('.jsUserMenuCloseBtn');
  const productActionBtnDigital = document.querySelector('.jsNavProductActionBtnDigital');
  const productActionBtnImages = document.querySelector('.jsNavProductActionBtnImages');
  const productActionBtnFinishes = document.querySelector('.jsNavProductActionBtnFinishes');
  const productActionBtnDocuments = document.querySelector('.jsNavProductActionBtnDocuments');
  const productActionBtnCustomizer = document.querySelector('.jsNavProductActionBtnCustomizer');
  const productImage = document.querySelector('.jsNavProductImg');
  const productImageBlank = document.querySelector('.jsNavProductImgBlank');
  const productCategoryBtns = document.querySelectorAll('.jsNavProductCategory');
  const imgItems = document.querySelectorAll('.jsNavigationContentContainer [data-img-element-id]');
  const aboutUsImgs = document.querySelectorAll('.jsNavAboutUsImages img');
  const aboutUsItems = document.querySelectorAll('.jsNavAboutUsItem');
  const rodoCheckbox = document.querySelector('.jsNavConactRodoCheckbox');
  const contactSubmitBtn = document.querySelector('.jsNavContactSubmitBtn');
  const contactListItems = document.querySelectorAll('.jsNavContactListItem');
  const liveChatBtn = document.querySelector('.jsNavContactLiveChatBtn');
  const ourWorkMobileBtn = document.querySelector('.jsNavOurWorkCategoriesMobileToggleBtn');
  const ourWorkListItems = document.querySelectorAll('.jsNavOurWorkProjectCategoryItem');
  const modalRegionListItems = document.querySelectorAll('.jsNavModalRegionList li');
  const userModal = document.querySelector('.jsNavUserModal');
  const userModalCloseBtn = document.querySelector('.jsNavUserModalCloseBtn');
  const navProductCollections = document.querySelector('.jsNavProductCollections');
  const blankImgSrc = productImageBlank.src;
  const ourWorkList = document.querySelector(".jsNavContentListOurWork");


  const handleOpenChat = () => {
    const iframe = document.querySelector('iframe#launcher').contentWindow.document;
    const button = iframe.querySelector('button');
    button.click();
  }

  // handle isChatOpen param
  function openChatDelay() {
    setTimeout(function () {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params.has('isChatOpen') && params.get('isChatOpen') === 'True') {
        handleOpenChat();
      }

    }, 200);
  }

  if (document.readyState == 'complete') {
    openChatDelay();
  } else {
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        openChatDelay();
      }
    }
  }

  // set height of the list so elements inside will wrap
  const listItemHeight = 38;
  const hideItems = 3;
  ourWorkList.style.height = `${(ourWorkList.children.length - hideItems) * listItemHeight / 2}px`;

  window.addEventListener('scroll', function () {
    const scrollY = window.scrollY;

    if (scrollY <= 20) {
      navContainer.classList.remove('navigation--borderBottom');
    } else {
      navContainer.classList.add('navigation--borderBottom');
    }
  });


  ourWorkMobileBtn.addEventListener('click', () => {
    ourWorkListItems.forEach(el => el.classList.toggle('navigation__hideOnMobile'));
    ourWorkMobileBtn.classList.toggle('navigationButton__content--downArrow')
  })

  liveChatBtn.addEventListener('click', () => {
    handleOpenChat();
  })

  contactListItems.forEach(el => el.addEventListener('mouseover', () => {
    contactFormLink.classList.remove(ACTIVE_CONTENT_BUTTON);
    hideElement(document.querySelector('.navigationContent__column[data-nav-content-section-id="contact-form"]'))
  }));

  rodoCheckbox.addEventListener('change', (e) => {
    if (e.target.checked && window.captchaVerified) {
      contactSubmitBtn.removeAttribute('disabled');
    } else {
      contactSubmitBtn.setAttribute('disabled', 'disabled');
    }
  })

  aboutUsItems.forEach((el, index) => el.addEventListener('mouseover', () => {
    aboutUsImgs.forEach(hideElement);
    showElement(aboutUsImgs[index]);
  }));

  imgItems.forEach(el => el.addEventListener('mouseover', ({target}) => {
    const imgElementId = target.dataset.imgElementId;
    const imgSrc = target.dataset.imgSrc;
    const img = document.querySelector(imgElementId);
    if (img) {
      if (isImgSrcEmpty(imgSrc)) {
        img.src = blankImgSrc;
      } else {
        img.src = imgSrc;
      }
    }
  }));

  // UTILS
  const disableAllNavHoverListItems = () => {
    jsNavHoverListItems.forEach(el => el.classList.remove(ACTIVE_CONTENT_BUTTON))
  };
  const disableAllProductButtons = () => {
    productButtons.forEach(el => {
      el.classList.remove(ACTIVE_CONTENT_BUTTON);
    })
  };
  const hideAllSecondLvlLists = () => {
    allSecondLvlLists.forEach(el => el.classList.add(HIDE_CLASS));
  }
  const isHidden = (element) => element.classList.contains(HIDE_CLASS);
  const showElement = (element) => element.classList.remove(HIDE_CLASS);
  const hideElement = (element) => element.classList.add(HIDE_CLASS);
  const hideMobileSearch = () => hideElement(mobileSearch);
  const showMobileSearch = () => showElement(mobileSearch);
  const showRegionModal = () => {
    regionButton.classList.add('navigation__countryBtn--active');
    showElement(regionModal);
  }
  const hideRegionModal = () => {
    regionButton.classList.remove('navigation__countryBtn--active');
    hideElement(regionModal);
  }
  const disableContentBtn = (element) => element.classList.remove(ACTIVE_CONTENT_BUTTON);
  const activeContentBtn = (element) => element.classList.add(ACTIVE_CONTENT_BUTTON);
  const showTopBarButtons = () => topBarButtons.classList.add('navTopBarButtons__show');
  const hideTopBarButtons = () => topBarButtons.classList.remove('navTopBarButtons__show');
  const hideNavContentContainers = () => navContentContainers.forEach(hideElement);
  const activeMobileNav = () => {
    navContainer.classList.add(mobileNavClass);
    navButtonsList.classList.remove('navigation__mobileHide');
    navButtonsList.classList.add('navigation__mobileFlex');
  }
  const disableMobileNav = () => {
    navButtonsList.classList.add('navigation__mobileHide');
    navButtonsList.classList.remove('navigation__mobileFlex');
    navContainer.classList.remove(mobileNavClass);
  }
  const isImgSrcEmpty = (src) => {
    return !src || src === '/media/';
  };

  const hideUserMenu = () => {
    hideElement(userModal);
    userBtn.classList.remove('navigation__actionBarBtn--user-active');
  }

  // USER MENU
  userBtn.addEventListener('click', () => {
    if (isHidden(userModal)) {
      userBtn.classList.add('navigation__actionBarBtn--user-active');
      showElement(userModal);
      return;
    }
    hideUserMenu();
  });

  userModalCloseBtn.addEventListener('click', () => {
    hideUserMenu();
  });

  userMenuCloseBtn.addEventListener('click', hideUserMenu);

  // SET REGION CODE INSIDE BUTTON
  const regionName = regionButton.getAttribute("data-region-name");
  let regionCode = "";
  let regionIndex = 0;

  switch (regionName) {
    case "Rest of World":
      regionCode = "RoW";
      regionIndex = 2;
      break;
    case "UK":
      regionCode = "UK";
      regionIndex = 0;
      break;
    case "US & Canada":
      regionCode = "US";
      regionIndex = 1;
      break;
    case "France":
      regionCode = "Fr"
      regionIndex = 3;
      break;
    case "Rest of Europe":
      regionCode = "RoE";
      regionIndex = 4;
      break;
    default:
      regionIndex = 0;
      regionCode = "UK";
  }
  modalRegionListItems[regionIndex]?.classList.add("navModalRegion__listItem--active")
  regionButton.innerText = regionCode;


  // REGION MODAL
  regionButton.addEventListener('click', () => {
    if (isHidden(regionModal)) {
      showRegionModal();
    } else {
      hideRegionModal();
    }
  });
  closeRegionModalBtn.addEventListener('click', () => {
    hideRegionModal();
  });

  // INSIGHTS LIVE MOBILE LIST
  insightLiveBtn.addEventListener('click', () => {
    if (isHidden(insightLiveList)) {
      hideElement(subjectExportSeriesList);
      showElement(insightLiveList);
      activeContentBtn(insightLiveBtn);
      disableContentBtn(subjectExportSeriesBtn)
    } else {
      hideElement(insightLiveList);
      disableContentBtn(insightLiveBtn);
    }
  });

  // SUBJECT EXPORT SERIES MOBILE LIST
  subjectExportSeriesBtn.addEventListener('click', () => {
    if (isHidden(subjectExportSeriesList)) {
      hideElement(insightLiveList);
      showElement(subjectExportSeriesList);
      activeContentBtn(subjectExportSeriesBtn);
      disableContentBtn(insightLiveBtn);
    } else {
      hideElement(subjectExportSeriesList);
      disableContentBtn(subjectExportSeriesBtn);
    }
  })

  // PRODUCTS
  productCategoryBtns.forEach(el => el.addEventListener('mouseover', () => {
    const src = el.dataset.imgSrc;
    if (isImgSrcEmpty(src)) {
      showElement(productImageBlank)
      hideElement(productImage);
      return;
    }
    hideElement(productImageBlank)
    showElement(productImage);
    productImage.src = src;
  }));


  jsNavHoverListItems.forEach(el => el.addEventListener('mouseover', ({target}) => {
    disableAllNavHoverListItems();
    disableAllProductButtons();
    el.classList.add(ACTIVE_CONTENT_BUTTON);
    const listId = target.dataset.targetListId;
    const targetList = document.querySelector(`.navList__list--listSecondLevel[data-target-list-id="${listId}"]`);

    if (listId?.length === 0) {
      hideElement(productActionList)
      hideMobileNav()
      hideAllSecondLvlLists();
      return;
    }
    if (targetList) {
      hideElement(productActionList)
      hideMobileNav()
      hideAllSecondLvlLists();
      showElement(targetList)
    }
  }));

  const isProductHasData = (element, attributeName) => element.getAttribute(attributeName) !== "None"

  let currentImgSrc = ''
  productButtons.forEach(el => el.addEventListener('mouseenter', () => {
    disableAllProductButtons();

    if (!el.dataset.productLink) {
      hideElement(productActionList);
      return;
    }

    const link = el.dataset.productLink;
    const img = el.dataset.imgSrc;


    if (isImgSrcEmpty(img)) {
      showElement(productImageBlank)
      hideElement(productImage);
    } else if (img !== currentImgSrc) {
      hideElement(productImageBlank)
      showElement(productImage);
      currentImgSrc = img;
      productImage.src = currentImgSrc;
    }

    if (link) {
      productActionBtnImages.href = `${link}?tab=images`;
      productActionBtnFinishes.href = `${link}?tab=finishes`;
      productActionBtnDocuments.href = `${link}?tab=resources`;
      productActionBtnDigital.href = `${link}?tab=cadFiles`;
      productActionBtnCustomizer.href = `${link.slice(0, -1)}#product-customizer-container`

      const checkProductData = (attributeName, listItemElement) => {
        if (el.getAttribute(attributeName) === "True") {
          showElement(listItemElement);
        } else {
          hideElement(listItemElement);
        }
      }

      checkProductData('data-product-customizer', productActionBtnCustomizer);
      checkProductData('data-product-files', productActionBtnDigital);
      checkProductData('data-product-images', productActionBtnImages);
      checkProductData('data-product-documents', productActionBtnDocuments);
      checkProductData('data-product-finishes', productActionBtnFinishes);
    }

    el.classList.add(ACTIVE_CONTENT_BUTTON);
    showElement(productActionList);
  }));

  allProductsLists.forEach(el => el.addEventListener('scroll', () => {
    hideElement(productActionList)
  }))


  navBackButtons2.forEach(el => {
    el.addEventListener('click', () => {
      el.parentElement.parentElement.parentElement.classList.add(HIDE_CLASS);
    })
  })

  navBackButtons.forEach(el => el.addEventListener('click', () => {
    disableAllNavHoverListItems();
    removeActiveNavButtons();
    hideAllContentContainers();
  }));

  // Event Handlers
  const toggleNavContent = (contentId) => {
    navContentContainer.classList.remove(HIDE_CLASS);

    navSections.forEach(el => {
      if (contentId === el.dataset.navigationContentId) {
        el.classList.remove(HIDE_CLASS);
      } else {
        el.classList.add(HIDE_CLASS);
      }
    });
  }

  const toggleInputVisibility = () => {
    inputContainer.classList.toggle(HIDE_CLASS);
    searchButton.classList.toggle("navigation__actionBarBtn--search-active")
  }

  const toggleMobileNav = () => {
    const isMenuActive = navContainer.classList.contains(mobileNavClass);

    if (isMenuActive) {
      document.querySelector('html').style.overflow = 'auto';
      disableMobileNav();
      hideMobileSearch();
      showNavMenuButton();
      hideTopBarButtons();
      hideNavContentContainers();
    } else {
      document.querySelector('html').style.overflow = 'hidden';
      activeMobileNav();
      showMobileSearch();
      showNavCloseButton();
      showTopBarButtons();
    }
  }

  const disactiveAllContentButtons = () => {
    document.querySelectorAll('.navigationButton__content').forEach(el => el.classList.remove(ACTIVE_CONTENT_BUTTON));
  }

  const hideAllNestedLists = () => {
    const contactForm = document.querySelector('.navigationContact__container[data-nav-content-section-id="contact-form"]');
    hideElement(contactForm);
    hideElement(insightLiveList);
    disactiveAllContentButtons();
    hideElement(productActionList)
  }

  const hideNavigation = () => {
    navSectionsContainer.classList.add(HIDE_CLASS);
    removeActiveNavButtons();
    hideMobileNav();
    hideAllContentContainers();
    hideMobileSearch();
    hideTopBarButtons();
    disableMobileNav();
    hideAllSecondLvlLists();
    hideAllNestedLists();
  }

  const handleOutsideClick = (event) => {
    if (!inputContainer.contains(event.target) && event.target !== searchButton) {
      hideElement(inputContainer);
      searchButton.classList.remove("navigation__actionBarBtn--search-active")
    }

    if (!regionModal.contains(event.target) && regionButton !== event.target) {
      hideRegionModal();
    }

    if (!userModal.contains(event.target) && event.target !== userBtn) {
      hideElement(userModal)
      userBtn.classList.remove('navigation__actionBarBtn--user-active');
    }
  }

  const removeActiveNavButtons = () => {
    navBarButtons.forEach(el => el.classList.remove(ACTIVE_NAV_BAR_BTN_CLASS));
  }

  const showNavCloseButton = () => {
    menuButton.firstElementChild.classList.remove('navigationIcon--menu');
    menuButton.firstElementChild.classList.add('navigationIcon--close');
  }

  const showNavMenuButton = () => {
    menuButton.firstElementChild.classList.add('navigationIcon--menu');
    menuButton.firstElementChild.classList.remove('navigationIcon--close');
  }

  const hideMobileNav = () => {
    document.querySelector('html').style.overflow = 'auto';
    navContainer.classList.remove(mobileNavClass);
    navButtonsList.classList.add('navigation__mobileHide');
    navButtonsList.classList.remove('navigation__mobileFlex');
    showNavMenuButton();
  }

  // Event Listeners
  menuButton.addEventListener('click', toggleMobileNav);

  searchButton.addEventListener('click', toggleInputVisibility);


  document.addEventListener('click', handleOutsideClick);

  if (window.innerWidth >= 922) {
    navBarButtons.forEach(el => el.addEventListener('mouseover', (event) => {
      hideAllNestedLists();
      removeActiveNavButtons();
      showNavCloseButton();
      navContainer.classList.add(mobileNavClass);
      event.target.classList.add(ACTIVE_NAV_BAR_BTN_CLASS);
      activeMobileNav();
      showTopBarButtons();
      toggleNavContent(event.target.dataset.navigationContentId);
    }));

    let hideNavContainerTimeout;
    navSectionsContainer.addEventListener('mouseover', () => {
      clearTimeout(hideNavContainerTimeout);
    });
    navSectionsContainer.addEventListener('mouseleave', () => {
      hideNavContainerTimeout = setTimeout(() => hideNavigation(), 500);
    });
    navContainer.addEventListener('mouseover', () => clearTimeout(hideNavContainerTimeout));


  } else {
    navBarButtons.forEach(el => el.addEventListener('click', (event) => {
      const {navigationContentId} = event.target.dataset;
      if (!navigationContentId) {
        return;
      }
      hideAllNestedLists();
      removeActiveNavButtons();
      showNavCloseButton();
      navContainer.classList.add(mobileNavClass);
      event.target.classList.add(ACTIVE_NAV_BAR_BTN_CLASS);
      activeMobileNav();
      showTopBarButtons();
      toggleNavContent(navigationContentId);
    }))
  }

  const contentContainers = document.querySelectorAll('.jsNavigationContentContainer');

  const hideAllContentContainers = () => {
    contentContainers.forEach(el => el.classList.add(HIDE_CLASS));
    contentLists.forEach(el => el.classList.remove(ACTIVE_CONTENT_BUTTON));
  }


  contentLists.forEach(el => el.addEventListener('mouseover', ({target}) => {
    const navContentSectionId = target.dataset.navContentSectionId;
    if (!navContentSectionId) return;
    const contentContainer = document.querySelector(`.navigationContact__container[data-nav-content-section-id="${navContentSectionId}"]`)
    if (!contentContainer) return;

    target.classList.add(ACTIVE_CONTENT_BUTTON);
    contentContainer.classList.remove(HIDE_CLASS);

  }));

  contentListsButtons.forEach(el => el.addEventListener('click', ({target}) => {
    const navContentSectionId = target.dataset.navContentSectionId;
    if (!navContentSectionId) return;
    const contentContainer = document.querySelector(`.navigationContact__container[data-nav-content-section-id="${navContentSectionId}"]`)
    if (!contentContainer) return;

    target.classList.add(ACTIVE_CONTENT_BUTTON);
    contentContainer.classList.remove(HIDE_CLASS);
  }));


  const navSearchForm = document.querySelector('.jsNavSearchForm');
  const navSearchInput = document.querySelector('.jsNavSearchInput');
  navSearchForm.addEventListener('submit', (e) => {
    e.preventDefault();
    window.location.href = `/search/${navSearchInput.value}/`
  });

  navProductCollections.addEventListener('mouseenter', () => {
    productImage.src = navProductCollections.getAttribute('data-img-src');
    disableAllNavHoverListItems();
    disableAllProductButtons();
    hideAllSecondLvlLists();
    hideElement(productActionList);
    hideElement(productImageBlank)
    showElement(productImage);
  })
}
